<div class="modal-home">
    <mat-dialog-content id="hellow">
    <div class="modal-home__bg">
      <div class="modal-home__flex">
        <div class="modal-home__site">
          <div class="modal-home__title">TEST</div>
          <div class="modal-home__box">
            <div class="modal-home__text">{{ data.name }}</div>
          </div>
        </div>
        <div class="modal-home__site-b">
          <img alt="PersonIcon" src="{{imagesBaseUrl}}//{{data.image}}" class="test__person" />
        </div>
      </div>
    </div>
    </mat-dialog-content>
    <div class="modal-home__para modal-home__drugs">
        <p class="modal-home__text-p">{{ data.description }}</p>
        <form [formGroup]="addiction" (ngSubmit)="onSubmit(data.id)">
          <mat-form-field class="width-100">
            <mat-select formControlName="addiction_id" required>
              <mat-option value="">Elige una opción</mat-option>
              <mat-option [value]="addiction.pivot.addiction_id+' '+addiction.name" *ngFor="let addiction of data.addictions">{{ addiction.name }}</mat-option>
            </mat-select>
          </mat-form-field>
          <div style="text-align: center;">
            <button class="btn btn-primary" type="submit">Hacer test</button>
          </div>
          </form>
    </div>
    <div align="end" class="btn-cancel">
      <button class="btn btn-secondary" [mat-dialog-close]="true" #close>Cerrar</button>
    </div>
  </div>
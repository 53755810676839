export const MaritalStatusValues = [{
  key: "1",
  label: "Soltero/a"
}, {
  key: "2",
  label: "Casado/a"
}, {
  key: "3",
  label: "Viudo/a"
}, {
  key: "4",
  label: "Divorciado/a"
}];
<div class="test-admin">
	<div class="test-admin__box">
		<h1 class="test-admin__title app-m-top">Editar Usuario Final</h1>
		<button mat-mini-fab color="primary" (click)="onCreationRequested()">
			<mat-icon>add</mat-icon>
		</button>
	</div>
	<div class="test-admin__card">
		<app-loader #loader></app-loader>

		<div *ngIf="!loader.loadingData">
			<div class="test-admin__box">
				<div class="home__search">
					<div class="inputContainer-search">
						<img alt="PersonIcon" src="../../../assets/icons/search_baseline.svg" class="inputContainer-search__icon" />
						<input
							class="inputContainer-search__box"
							type="text"
							placeholder="Palabra clave"
							[(ngModel)]="dataSource.filter"
						/>
					</div>
				</div>
				<div align="end" class="btn-cancel">
					<button class="btn btn-file app-m-right" (click)="onExcelExportRequested()"><mat-icon class="icon-file">description</mat-icon>Excel</button>
					<!--<button class="btn btn-file" (click)="onPDFExportRequested()"><mat-icon class="icon-file">picture_as_pdf</mat-icon>PDF</button>-->
				</div>
			</div>

			<table mat-table [dataSource]="dataSource" matSort>
				<ng-container matColumnDef="userId">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Id</th>
					<td mat-cell *matCellDef="let element">{{element.userId}} </td>
				</ng-container>
				<ng-container matColumnDef="firstNames">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Nombres</th>
					<td mat-cell *matCellDef="let element">{{element.firstNames}}</td>
				</ng-container>
				<ng-container matColumnDef="lastNames">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Apellidos</th>
					<td mat-cell *matCellDef="let element">{{element.lastNames}} </td>
				</ng-container>
				<ng-container matColumnDef="email">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
					<td mat-cell *matCellDef="let element">{{element.email}} </td>
				</ng-container>
				<ng-container matColumnDef="reference">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Pertenece a</th>
					<td mat-cell *matCellDef="let element">{{element.reference}} </td>
				</ng-container>
				<ng-container matColumnDef="statusLabel">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Estado</th>
					<td mat-cell *matCellDef="let element">{{element.statusLabel}}</td>
				</ng-container>
				<ng-container matColumnDef="actions">
					<th mat-header-cell *matHeaderCellDef>Edición</th>
					<td mat-cell *matCellDef="let element">
						<mat-icon fontSet="material-icons-outlined" class="app-cursor" (click)="onEditRequested(element.userId)">edit</mat-icon>
						<mat-icon fontSet="material-icons-outlined" class="app-cursor" (click)="onUserRemovalRequested(element)">delete</mat-icon>
					</td>
				</ng-container>
				<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
				<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
			</table>
			
			<mat-paginator [length]="resultsLength" [pageSize]="30"></mat-paginator>
		</div>
	</div>
</div>
<div class="modal-result__content">
    <div class="modal-result__head">
      <div class="modal-result__title">¿Qué significa mi resultado?</div>
      <div class="modal-result__sub-title">
        <div [innerHTML]="gifLevel" class="img-lvl"></div>
        <div [style]="colorsLevel">{{ level }}</div>
      </div>
    </div>
    <div class="modal-result__content">
      <div *ngIf="level == 'Leve'">
        Obtener como resultado el riesgo leve significa que existen algunos factores de 
        riesgo asociados con el comportamiento o el consumo que estás presentando.
         Es importante prestarles atención, pero no son graves, ni están generando disfuncionalidad en tu vida. 
         Es prioritario reconocer los riesgos y buscar factores protectores (estilo de vida saludable) para enfrentarlos.
      </div>
      <div *ngIf="level == 'Moderado'">
        Obtener como resultado el riesgo moderado significa que existen factores de riesgo asociados 
        con el comportamiento o el consumo que estás presentando. Es importante prestarles atención, 
        pues el abuso te puede llevar a tener dificultades asociadas a disfuncionalidad en tu vida, por ejemplo, 
        afectar tu entorno familiar, laboral o afectivo. Es prioritario reconocer los riesgos y buscar factores protectores 
        (estilo de vida saludable) para enfrentarlos, por ser un nivel moderado es recomendable buscar acompañamiento de un especialista en el tema.
      </div>
      <div *ngIf="level == 'Severo'">
        Obtener como resultado el riesgo severo significa que existen muchos factores de riesgo asociados con el 
        comportamiento o el consumo que estás presentando. Es importante identificarlos porque lo más probable es que 
        ya están afectando tu entorno personal, familiar o social. Por ser un nivel severo es recomendable buscar 
        acompañamiento de un especialista en el tema.
      </div>
      <div *ngIf="level == 'Ausencia de Ansiedad'">
        El resultado obtenido significa que pueden existir algunos síntomas de ansiedad, pero no son suficientes 
        para determinar señales de alarma con respecto a este trastorno. No obstante, es fundamental detectar los 
        signos que te llevaron a realizar este cuestionario, para crear nuevas estrategias de afrontamiento y 
        evitar que la sintomatología se aumente; siempre es mejor detectar a tiempo.
      </div>
      <div *ngIf="level == 'Presencia de Ansiedad'">
        El resultado obtenido significa que existen síntomas de ansiedad y que es importante buscar asesoría al respecto. 
        Probablemente hay factores en tu vida que aumentan la aparición de estos síntomas y es vital identificarlos e intervenirlos. 
        El cuestionario es solo una herramienta para identificar algunos signos o síntomas, pero es prioritario contar con el 
        acompañamiento de un experto.
      </div>
      <div *ngIf="level == 'Ausencia de depresión'">
        El resultado obtenido significa que pueden existir algunos síntomas de depresión, pero no son suficientes para 
        determinar señales de alarma con respecto a este trastorno. No obstante, es fundamental detectar los signos que te 
        llevaron a realizar este cuestionario para crear nuevas estrategias de afrontamiento y evitar que la sintomatología 
        se aumente, siempre es mejor detectar a tiempo.
      </div>
      <div *ngIf="level == 'Presencia de depresión'">
        El resultado obtenido significa que existen síntomas de depresión y que es importante buscar asesoría al respecto. 
        Probablemente hay factores en tu vida que aumentan la aparición de estos síntomas y es vital identificarlos e intervenirlos. 
        El cuestionario es solo una herramienta para identificar algunos signos o síntomas, pero es prioritario contar con el acompañamiento 
        de un experto.
      </div>
    </div>
    <div align="end" class="btn-cancel">
        <button class="btn btn-secondary" [mat-dialog-close]="true">Aceptar</button>
      </div>
  </div>

<div class="modal-home">
  <mat-dialog-content id="hellow">
  <div class="modal-home__bg">
    <div class="modal-home__flex">
      <div class="modal-home__site">
        <div class="modal-home__title">TEST</div>
        <div class="modal-home__box">
          <div class="modal-home__text">{{ data.test.name }}</div>
        </div>
      </div>
      <div class="modal-home__site-b">
        <img alt="PersonIcon" src="{{imagesBaseUrl}}/{{data.test.image}}" class="test__person" />
      </div>
    </div>
  </div>
  </mat-dialog-content>
  <div class="modal-home__para">
    <div class="modal-home__grey" [innerHTML]="data.test.introduction">
    </div>
  </div>
  <div align="end" class="btn-cancel">
    <button class="btn btn-secondary" [mat-dialog-close]="true">Cerrar</button>
  </div>
</div>
<div class="confidencial">
    <img src="./../../../assets/bg/MANO-CORAZON.svg" alt="presentation image" class="confidencial__img">
    <div class="confidencial__text">
      A continuación, se te harán una serie de preguntas, responde de manera <span class="confidencial__strong">HONESTA</span> así el resultado obtenido será <span>CONFIABLE</span>.
    </div>
    <div class="confidencial__text">
      Recuerda que tu reporte es <span class="confidencial__strong">COMPLETAMENTE CONFIDENCIAL</span>.
    </div>
  </div>
  <div align="end" class="btn-cancel">
    <button class="btn btn-secondary" [mat-dialog-close]="true">Aceptar</button>
  </div>

<div class="test-admin">
  <div class="test-admin__box">
    <h1 class="test-admin__title app-m-top">Test Informe</h1>
    <!-- <button
      mat-mini-fab
      color="primary"
      aria-label="Example icon button with a delete icon"
    >
      <mat-icon>add</mat-icon>
    </button> -->
  </div>
  <div class="test-admin__card">
    <app-loader #loader></app-loader>
    <div [hidden]="loader.loadingData">
      <div class="test-admin__box" >
        <div class="home__search">
          <div class="inputContainer-search">
            <img
              alt="PersonIcon"
              src="../../../assets/icons/search_baseline.svg"
              class="inputContainer-search__icon"
            />
            <input
              class="inputContainer-search__box"
              type="text"
              placeholder="Palabra clave"
              [(ngModel)]="dataSource.filter"
            />
          </div>
        </div>
        <div align="end" class="btn-cancel">
          <button
            class="btn btn-file app-m-right"
            (click)="onExcelExportRequested()"
          >
            <mat-icon class="icon-file">description</mat-icon>Excel
          </button>
          <!--<button class="btn btn-file" (click)="onPDFExportRequested()">
            <mat-icon class="icon-file">picture_as_pdf</mat-icon>PDF
          </button>-->
        </div>
      </div>
      <div class="example-table-container">
        <table
          mat-table
          [dataSource]="dataSource"
          matSort
          matSortActive="position"
          matSortDisableClear
          matSortDirection="desc"
          [hidden]="loader.loadingData"
          class="example-table"
        >
          <!--Id Usuario-->
          <ng-container matColumnDef="userId">
            <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear class="padding-left-table">
              Id Usuario
            </th>
            <td mat-cell *matCellDef="let element" class="padding-left-table">{{ element.userId }}</td>
          </ng-container>
          <!--Id Test-->
          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
              Id
            </th>
            <td mat-cell *matCellDef="let element">{{ element.id }}</td>
          </ng-container>
          <!--Test-->
          <ng-container matColumnDef="testName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
              Test
            </th>
            <td mat-cell *matCellDef="let element">{{ element.testName }}</td>
          </ng-container>
          <!--Fecha-->
          <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
              Fecha
            </th>
            <td mat-cell *matCellDef="let element">{{ element.date }}</td>
          </ng-container>
          <!--Hora-->
          <ng-container matColumnDef="time">
            <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
              Hora
            </th>
            <td mat-cell *matCellDef="let element">{{ element.time }}</td>
          </ng-container>
          <!--Usuario-->
          <ng-container matColumnDef="userName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
              Usuario
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.userName }}
            </td>
          </ng-container>
          <!--Nivel-->
          <ng-container matColumnDef="resultLevel">
            <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
              Nivel
            </th>
            <td mat-cell *matCellDef="let element">
                <div [className]="getTestChipClass(element.resultLevel)">{{
                  element.resultLevel
                }}</div>
            </td>
          </ng-container>
          <!--Telefono-->
          <ng-container matColumnDef="phone">
            <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
              Teléfono
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.phone }}
            </td>
          </ng-container>
          <!--Correo Electronico-->
          <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
              Correo Electronico
            </th>
            <td mat-cell *matCellDef="let element">{{ element.email }}</td>
          </ng-container>
          <!--Fecha Nacimiento-->
          <ng-container matColumnDef="birthdate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
              Fecha Nacimiento
            </th>
            <td mat-cell *matCellDef="let element">{{ element.birthdate }}</td>
          </ng-container>
          <!--Nivel Escolaridad-->
          <ng-container matColumnDef="educationalLevel">
            <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
              Nivel Escolaridad
            </th>
            <td mat-cell *matCellDef="let element">{{ element.educationalLevel }}</td>
          </ng-container>
          <!--Ciudad-->
          <ng-container matColumnDef="city">
            <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
              Ciudad
            </th>
            <td mat-cell *matCellDef="let element">
              <span>{{
                element.city
              }}</span>
            </td>
          </ng-container>
          <!--Casco-->
          <ng-container matColumnDef="zone">
            <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
              Casco
            </th>
            <td mat-cell *matCellDef="let element">
              <span>{{
                element.zone
              }}</span>
            </td>
          </ng-container>
          <!--Comuna/Corregimiento-->
          <ng-container matColumnDef="optionA">
            <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
              Comuna/Corregimiento
            </th>
            <td mat-cell *matCellDef="let element">
              <span>{{
                element.optionA
              }}</span>
            </td>
          </ng-container>
          <!--Barrio/Vereda-->
          <ng-container matColumnDef="optionB">
            <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
              Barrio/Vereda
            </th>
            <td mat-cell *matCellDef="let element">
              <span>{{
                element.optionB
              }}</span>
            </td>
          </ng-container>
          <!--Universidad-->
          <ng-container matColumnDef="university">
            <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
              Universidad
            </th>
            <td mat-cell *matCellDef="let element">{{ element.university }}</td>
          </ng-container>
          <!--Programa-->
          <ng-container matColumnDef="program">
            <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
              Programa
            </th>
            <td mat-cell *matCellDef="let element">{{ element.program }}</td>
          </ng-container>
          <!--Modalidad-->
          <ng-container matColumnDef="modality">
            <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
              Modalidad
            </th>
            <td mat-cell *matCellDef="let element">{{ element.modality }}</td>
          </ng-container>
          <!--Semestre-->
          <ng-container matColumnDef="semester">
            <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
              Semestre
            </th>
            <td mat-cell *matCellDef="let element">{{ element.semester }}</td>
          </ng-container>
           <!--Empresa-->
           <ng-container matColumnDef="company">
            <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
              Empresa
            </th>
            <td mat-cell *matCellDef="let element">{{ element.company }}</td>
          </ng-container>
          <!--Sede-->
          <ng-container matColumnDef="location">
            <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
              Sede
            </th>
            <td mat-cell *matCellDef="let element">{{ element.location }}</td>
          </ng-container>
          <!--Area-->
          <ng-container matColumnDef="area">
            <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
              Area
            </th>
            <td mat-cell *matCellDef="let element">{{ element.area }}</td>
          </ng-container>
          <!--Turno-->
          <ng-container matColumnDef="schedul">
            <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
              Turno
            </th>
            <td mat-cell *matCellDef="let element">{{ element.schedul }}</td>
          </ng-container>
          <!--Institucion-->
          <ng-container matColumnDef="institution">
            <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
              Institucion
            </th>
            <td mat-cell *matCellDef="let element">{{ element.institution }}</td>
          </ng-container>
          <!--Grado-->
          <ng-container matColumnDef="grade">
            <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
              Grado
            </th>
            <td mat-cell *matCellDef="let element">{{ element.grade }}</td>
          </ng-container>
          <!--Genero-->
          <ng-container matColumnDef="gender">
            <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
              Género
            </th>
            <td mat-cell *matCellDef="let element">{{ element.gender }}</td>
          </ng-container>
          <!--Estado Civil-->
          <ng-container matColumnDef="civilStatus">
            <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
              Estado Civil
            </th>
            <td mat-cell *matCellDef="let element">{{ element.civilStatus }}</td>
          </ng-container>
          <!--Modal-->
          <ng-container matColumnDef="answers">
            <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
              Ver respuestas
            </th>
            <td mat-cell *matCellDef="let element">
              <mat-icon
                class="app-cursor"
                fontSet="material-icons-outlined"
                (click)="openDialogTestModalReport(element)"
                >live_help</mat-icon
              >
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>
      <mat-paginator [length]="resultsLength" [pageSize]="30"></mat-paginator>
    </div>
    
  </div>
</div>

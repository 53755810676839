<div class="test">
	<div class="test__content">
		<div class="test__info">
			<img alt="PersonIcon" src="../../../assets/icons/close_twotone.svg" class="test__icon" routerLink="/" />
			<div class="test__text">{{firstNames}}</div>
			<div class="test__flex">
				<div class="test__title">{{lastNames}}</div>
			</div>
			<img alt="PersonIcon" *ngIf="gender != 1" src="../../../assets/bg/HOMBRE-PERFIL.svg" class="test__person" />
      <img alt="PersonIcon" *ngIf="gender == 1" src="../../../assets/bg/MUJER-PERFIL.svg" class="test__person" />
		</div>
		<div class="test__box">
			<mat-tab-group>
        <mat-tab>
            <ng-template mat-tab-label class="tab-test">
              <mat-icon class="example-tab-icon" fontSet="material-icons-outlined">account_circle</mat-icon>
              Mis datos
            </ng-template>
            <div class="test__profile">
              <profile-form profile-update="true" *ngIf="!userIsAdmin"></profile-form>
              <user-system-form profile-update="true" *ngIf="userIsAdmin"></user-system-form>
            </div>
        </mat-tab>
        <mat-tab> 
            <ng-template mat-tab-label>
                <mat-icon class="example-tab-icon"  fontSet="material-icons-outlined">live_help</mat-icon>
                Mis test
              </ng-template>
            <div class="table-container">
            <table mat-table [dataSource]="dataSource">
                <ng-container matColumnDef="Test">
                <th mat-header-cell *matHeaderCellDef>Test </th>
                <td mat-cell *matCellDef="let element">{{ element.addiction == null ? element.testName : element.testName +' - '+element.addiction['name'] }}</td>
                </ng-container>
                <ng-container matColumnDef="Fecha">
                <th mat-header-cell *matHeaderCellDef>Fecha</th>
                <td mat-cell *matCellDef="let element">{{ element.date }}</td>
                </ng-container>
                <ng-container matColumnDef="Nivel">
                <th mat-header-cell *matHeaderCellDef>Nivel</th>
                <td mat-cell *matCellDef="let element"><span class="span-uppercase" [className]="getTestChipClass(element.resultLevel)">{{ element.resultLevel }}</span></td>
                </ng-container>
              
                <!-- Symbol Column -->
                <ng-container matColumnDef="Respuestas">
                  <th mat-header-cell *matHeaderCellDef> Respuesta </th>
                  <td mat-cell *matCellDef="let element">
                    <mat-icon fontSet="material-icons-outlined" (click)="openDialogProfile(element)" class="icon-live">live_help</mat-icon>
                    </td>
                </ng-container>
              
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>
        </mat-tab>
      </mat-tab-group>
		</div>
	</div>
</div>

<!--HOME-->
<div class="home">
	<h1 class="home__title">Por favor elige un test</h1>
	<div class="home__search">
		<div class="inputContainer">
			<img alt="PersonIcon" src="../../../assets/icons/search_baseline.svg" class="inputContainer__icon" />
			<form [formGroup]="SearchForm">
				<input
				class="inputContainer__box"
				type="text"
				placeholder="Drogas, alcohol, depresión"
				formControlName="search" (keyup)="arrayFilter()"
				/>
			</form>
		</div>
	</div>
	<div class="card__content">
		<div class="card__item" *ngFor="let test of tests">
			<div class="card__img">
				<img src="../../../assets/icons/helpoutline_baseline.svg" alt="" (click)="openDialog(test)">
			</div>
			<div class="card__risk" ><span *ngFor="let category of test.categories"> {{ category.name }} <br></span></div>
			<div class="card__text">{{ test.name }}</div>
			<img *ngIf="test.image == ''" src="../../../assets/bg/apprevenir-ludopatia.png" alt="test image" class="card__img-man">
          	<img *ngIf="test.image != ''" src="{{imagesBaseUrl}}/{{test.image}}" alt="test image" class="card__img-man">
			<button *ngIf="test.addictions.length > 0 && test.id != 2" class="btn btn-primary" (click)="openDialogHome(test)">Hacer test</button>
			<button  *ngIf="test.id == 2 || test.addictions.length == 0" class="btn btn-primary" routerLink="/test/{{test.id}}">Hacer test</button>
		</div>
	</div>
</div>
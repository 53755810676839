<div class="test">
	<div class="test__content">
		<div class="test__info">
			<img alt="PersonIcon" src="../../../assets/icons/close_twotone.svg" class="test__icon" routerLink="/app/home" />
			<div class="test__title">TEST</div>
			<div class="test__flex">
				<div class="test__text">{{ test['name'] }} <span *ngIf="addiction && test['name']" class="addiction"> - {{ addiction }}</span></div>
				<img 
				alt="PersonIcon" 
				src="../../../assets/icons/info_outline.svg"
				class="inputContainer__icon app-cursor"
				(click)="openDialogInformation(test)" />
			</div>
			<img alt="PersonIcon" src="{{imagesBaseUrl}}/{{test['image']}}" class="test__person" />
		</div>
		<div class="test__box">
			<form class="test-form" [formGroup]="formGroup" *ngIf="!result">
				<mat-horizontal-stepper linear #stepper formArrayName="formArray" class="one-steps">
					<mat-step formGroupName="{{i}}" [stepControl]="formArray?.get([i])" *ngFor="let question of questions; let i = index">
						<div class="test__ask">
							<div class="test__number">
								{{ question.order }}
							</div>
							<div class="test__question">
								{{ question.question }}
							</div>
						</div>
						<div class="app-m-top-2">
							<div *ngIf="test['name'] == 'Drogas'" id="drugs-test">
								<div *ngIf="question.order == 1">
								  <div *ngFor="let addiction of test.addictions; let in = index" class="drugs-left">
									<mat-checkbox disableRipple="true" color="primary" formControlName="addiction" required value="{{ in }}" 
									(change)="selectAddiction($event)">
										<label class="test__label" >{{ order[in] }}</label>
										<div class="check-div">{{ addiction.description }}</div>
									</mat-checkbox>
								  </div>
								</div>
								
								<div *ngIf="question.order != 1">
								  <div *ngFor="let addictionSelect of addictionArray; let inx = index">
									<div  class="test-form__addiction">
									  <label class="test__label">{{ order[addictionSelect.order] }}</label>
									  <div class="test-form__option-answer">{{ addictionSelect.desc }}</div>
									</div>
									
									<mat-form-field class="example-full-width">
									  <mat-label>Elige una opción</mat-label>
									  <mat-select formControlName="answer_{{addictionSelect.id}}_{{question.id}}" required >
										<mat-option [value]="answer.id"  *ngFor="let answer of question.answers; let in = index" >
										  {{ answer.answer }}
										</mat-option>
									  </mat-select>
									</mat-form-field>
								  </div>
								</div>
							</div>
						</div>

						<div class="test__radio">
							<mat-radio-group formControlName="answer_{{question.id}}" required class="test__radio-flex" 
								*ngIf="test['name'] != 'Drogas'">
								<mat-radio-button value="{{ answer.id }}" *ngFor="let answer of question.answers; let in = index">
									<label class="test__label">{{ order[in] }}</label>
									<div class="test-form__option-answer">{{ answer.answer }}</div>
								</mat-radio-button>
							</mat-radio-group>
						</div>

						<div class="app-flex">
							<button class="btn btn-secondary app-m-right" *ngIf="i == 0" routerLink="/app/home">Anterior</button>
							<button *ngIf="i != 0" class="btn btn-secondary app-m-right" matStepperPrevious>Anterior</button>
							<button *ngIf="i != questions.length - 1" class="btn btn-primary" matStepperNext> Siguiente</button>
							<button *ngIf="i == questions.length - 1" class="btn btn-primary" matStepperNext (click)="onSubmit()">Enviar</button>
						</div>
					
					</mat-step>
				</mat-horizontal-stepper>
			</form>

			<!--Vista de resultado de test-->	
			<mat-tab-group *ngIf="result">
				<mat-tab>
					<ng-template mat-tab-label>
						<mat-icon fontSet="material-icons-outlined">account_circle</mat-icon>
						Resultado
					</ng-template>
					<div class="test__tab" *ngFor="let testResult of testResults; let i = index">
						<div class="test__type">
							<mat-icon fontSet="material-icons-outlined" class="test__ico" (click)="openDialogSuppor(testResult.resultLevel)">help_outline</mat-icon>
							<div [innerHTML]="gifLevel[testResult.resultLevel]"></div>
            				<div [style]="colorsLevel[testResult.resultLevel]" class="test-result">{{testResult.resultLevel}}</div>
							<div [style]="colorsLevel[testResult.resultLevel]" class="test-result">{{testResult.addiction}}</div>
						</div>
					</div>
				</mat-tab>
			
				<mat-tab>
					<ng-template mat-tab-label>
						<mat-icon fontSet="material-icons-outlined">play_circle_outline</mat-icon>
						Información
					</ng-template>
					<div class="test-tab-video">
						<div class="test__type width-100" *ngFor="let testResult of testResults; let i = index">
							<div [style]="colorsLevel[testResult.resultLevel]" class="test-result-video">{{testResult.addiction}}</div>
							<iframe width="700px" height="405px" [src]="getVideoIframe(testResult.url_video)" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
						</div>
					</div>
				</mat-tab>
			
				<mat-tab>
					<ng-template mat-tab-label>
						<mat-icon fontSet="material-icons-outlined">support_agent</mat-icon>
						Ayuda
					</ng-template>
					<div class="test-tab-info" *ngFor="let testResult of testResults; let i = index">
						<div [style]="colorsLevel[testResult.resultLevel]" class="test-result-info">{{testResult.addiction}}</div>
						<div class="test__info-text" [innerHTML]="testResult.professional_help"></div>
					</div>
				</mat-tab>

				<mat-tab>
					<ng-template mat-tab-label>
						<mat-icon fontSet="material-icons-outlined">school</mat-icon>
						Enlaces
					</ng-template>
					<div class="test__tab">
						<div class="test__info-box">
							<div class="test__school" *ngFor="let testResult of testResults; let i = index">
								<div [style]="colorsLevel[testResult.resultLevel]" class="test-result-link">{{testResult.addiction}}</div>
								<div class="test-result-box">
									<mat-icon fontSet="material-icons-outlined">school</mat-icon>
									<div [innerHTML]="testResult.url_interest" id="gif_level"></div>
								</div>
							</div>
						</div>
					</div>
				</mat-tab>

			</mat-tab-group>
		</div>
	</div>
</div>

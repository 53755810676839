<mat-dialog-content>
    <div class="modal-box">
        <p class="level__text">Respuestas del test de {{ data.testName }}</p>
        <div class="level">
            <div class="level__title">Nivel <span *ngIf="data.addiction"> - {{ data.addiction.name }} </span><span [ngStyle]=setStyle(data.resultLevel)> {{ data.resultLevel }} </span></div>
        </div>
        <table mat-table [dataSource]="dataSource">
            <ng-container matColumnDef="N">
              <th mat-header-cell *matHeaderCellDef>N </th>
              <td mat-cell *matCellDef="let element">{{ element.question.order }}</td>
            </ng-container>
            <ng-container matColumnDef="Pregunta">
              <th mat-header-cell *matHeaderCellDef>Pregunta</th>
              <td mat-cell *matCellDef="let element">{{ element.question.question }}</td>
            </ng-container>
            <ng-container matColumnDef="Respuesta">
              <th mat-header-cell *matHeaderCellDef>Respuesta</th>
              <td mat-cell *matCellDef="let element">{{ element.answer }}</td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
    </div>
    <div align="end" class="btn-cancel">
        <button class="btn btn-secondary" [mat-dialog-close]="true">Cerrar</button>
    </div>
</mat-dialog-content>
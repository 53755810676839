<mat-dialog-content class="mat-typography">
    <div class="modal-h3">Términos y condiciones generales de uso</div>
    <div class="modal-box">
        <p class="modal-title">I. PROPÓSITO</p>
        <p class="modal-text">
          La presente política se expide en cumplimiento de la Ley Estatutaria 1581 de 2012 y el Decreto Único Reglamentario Sectorial 1074 de 2015 sobre el régimen de protección de datos personales y busca garantizar que Apprevenir, en condición de responsable del manejo de datos personales, realice el tratamiento de los mismos en estricto cumplimiento de la normatividad aplicable, garantizando asimismo los derechos de los titulares de los datos.
        </p>
        <br>
        <p class="modal-title">II. RESPONSABLE</p>
        <p class="modal-text">
          Denominación: Apprevenir <br>
          Dirección: Transversal 51A #67B 90 Medellín - Colombia. <br>
          Ciudad: Medellín, Antioquia <br>
          Correo Electrónico: contacto.apprevenir@amigo.edu.co <br>
          Teléfono: +57 604 448 7666 <br>
          Área a cargo: Oficina de Protección y Tratamiento de Datos Personales.
        </p>
        <br>
        <p class="modal-title">III. PRINCIPIOS PARA EL TRATAMIENTO DE DATOS PERSONALES</p>
        <p class="modal-text">
          En el desarrollo, interpretación y aplicación de la presente política, se aplicarán de manera armónica e integral, los siguientes principios: <br>
          1. Principio de legalidad en materia de Tratamiento de datos: Todo tratamiento de datos personales está regulado por la Ley 1581 de 2012 y el Decreto 1074 de 2015. <br>
          2. Principio de finalidad: El tratamiento debe obedecer a una finalidad legítima de acuerdo con la Constitución y la Ley, la cual debe ser informada al Titular. <br>
          3. Principio de libertad: El tratamiento sólo puede ejercerse con el consentimiento previo, expreso e informado del titular. Los datos personales no podrán ser obtenidos o divulgados sin previa autorización, o en ausencia de mandato legal o judicial que releve el consentimiento. <br>
          4. Principio de veracidad o calidad: La información sujeta a tratamiento debe ser veraz, completa, exacta, actualizada, comprobable y comprensible. Se prohíbe el tratamiento de datos parciales, incompletos, fraccionados o que induzcan a error, salvo que el titular así lo autorice. <br>
          5. Principio de transparencia: Se garantiza al titular su derecho a obtener en cualquier momento y sin restricciones, información respecto de sus datos personales que reposen en las bases de datos de Apprevenir. <br>
          6. Principio de acceso y circulación restringida: Los datos personales que no tengan carácter público sólo podrán estar disponibles en internet u otros medios de comunicación masiva cuando el acceso a ellos sea técnicamente controlable por Apprevenir y sólo podrán ser accedidos de manera restringida por el titular, por quien este autorice y/o por las personas previstas en la Ley. <br>
          7. Principio de seguridad: La información sujeta a tratamiento por el responsable o encargado se deberá manejar con las medidas técnicas, humanas y administrativas que sean necesarias para otorgar seguridad a los registros evitando su adulteración, pérdida, consulta, uso o acceso no autorizado o fraudulento. <br>
          8. Principio de confidencialidad: Todas las personas que intervengan en el tratamiento de datos personales que no tengan la naturaleza de públicos están obligadas a garantizar la reserva de la información, inclusive después de finalizada su relación con alguna de las labores que comprende el tratamiento, pudiendo sólo suministrar o comunicar datos personales cuando ello corresponda al desarrollo de las actividades autorizadas en la Ley.
        </p>
        <br>
        <p class="modal-title">IV. TRATAMIENTO AL CUAL SERÁN SOMETIDOS LOS DATOS</p>
        <p class="modal-text">
          Apprevenir  trata los datos personales de sus clientes, aliados, empleados, funcionarios, contratistas y proveedores dentro del desarrollo de sus actividades. Esto lo realiza directamente, a través de sus empleados, funcionarios o por parte de mandatarios encargados de ello.
  
          Asimismo, comparte los datos con terceros ubicados en Colombia y en exterior con quienes se celebra contratos para la transferencia y/o trasmisión de datos personales, según corresponda, con el propósito de mantenerlos seguros y protegidos de conformidad con las reglas y estándares aplicables.
  
          Apprevenir tiene la obligación de mantener la confidencialidad de los datos personales objeto de tratamiento y sólo podrá divulgarlos por solicitud expresa de las entidades de vigilancia y control y por autoridades que tengan la facultad legal de solicitarla.
  
          En todo momento los titulares podrán de manera gratuita conocer, actualizar, rectificar y corregir su información personal de conformidad con el artículo 8 de la Ley 1581 de 2012.
  
          En desarrollo del principio de finalidad, la recolección de datos personales por parte de Apprevenir se limita a aquellos datos personales que son pertinentes y adecuados para cumplir con las finalidades expresadas en la presente política. Salvo en los casos expresamente previstos en la Ley, no se podrán recolectar datos personales sin autorización del Titular.
  
          El tratamiento de datos incluye la recolección, almacenamiento, administración, utilización, transferencia, transmisión y destrucción, en la forma permitida por la Ley y se realiza con las siguientes finalidades específicas: <br>
  
          1. Promoción, protección y recuperación de la salud, y de los derechos sexuales y reproductivos a través de: <br>
        </p>
          <ol class="modal-text">
            <li>
              Respuestas a las preguntas realizadas por los usuarios.
            </li>
            <li>
              Elaboración de procesos de seguimiento y evaluación sobre la prestación del servicio a través de encuestas.
            </li>
            <li>
              Envío de información y contenido de interés específico.
            </li>
            <li>
              Identificación de epidemias y generación de alertas sobre éstas.
            </li>
            <li>
              Elaboración de estudios sobre hábitos de consumo y enfermedades.
            </li>
            <li>
              Elaboración de análisis estadísticos, demográficos y de mercado.
            </li>
            <li>
              Desarrollo de estudios y publicaciones de carácter académico y periodístico relacionadas con la información recolectada por Apprevenir
            </li>
            <li>
              Ofrecimiento de servicios personalizados para cada usuario acorde con la información recolectada.
            </li>
            <li> Desarrollo de actividades comerciales conjuntas con compañías o entidades vinculadas o aliadas.</li>
          </ol>
          <p class="modal-text">2. Mantener comunicación periódica y efectiva con los usuarios de nuestra plataforma, clientes, afiliados, empleados, funcionarios, proveedores, y cualquier persona respecto de la cual estemos autorizados para efectuar el tratamiento de sus datos personales, por medio de:</p>
          <ol class="modal-text">
            <li>
              Ofrecimiento y promoción, por cualquier medio, de servicios de Apprevenir, de compañías vinculadas o de cualquier persona o compañía.
            </li>
            <li>
              Envío de invitaciones para participar en proyectos, estudios y/o eventos organizados por Apprevenir, empresas vinculadas y terceros, relacionados con los servicios prestados a través de la plataforma.
            </li>
            <li>
              Transmisión de datos a empresas vinculadas y terceros para la realización de actividades gremiales, médico-científicas y todas aquellas relacionadas con la salud.
            </li>
            <li>
              Suministro de información a empresas vinculadas y terceros acerca los casos de urgencia médica o sanitaria.
            </li>
            <li>
              Suministro de información y generación de alerta acerca de los casos de vulneración de derechos de la población adolescente y joven.
            </li>
          </ol>
          <p class="modal-text">3. Lograr una adecuada prestación de los servicios ofrecidos por Apprevenir a través de:</p>
          <ol class="modal-text">
            <li>
              Permitir el acceso a la información sobre peticiones, quejas y reclamos presentados por los usuarios, clientes, aliados, contratistas y proveedores con ocasión de actividades desarrolladas por Apprevenir.
            </li>
            <li>
              Usos contables, administrativos, comerciales, informativos, de mercadeo y ventas.
            </li>
            <li>
              La transmisión de datos personales a encargados para su almacenamiento en servidores ubicados dentro y/o fuera de Colombia.
            </li>
            <li>
              La ejecución de actividades relacionadas con el vínculo laboral con sus empleados.
            </li>
            <li>
              Cualquier otra finalidad que corresponda según el vínculo que se genere entre los titulares de los datos personales y la compañía.
            </li>
          </ol>
        <br>
        <p class="modal-title">V. TRATAMIENTO DE DATOS PERSONALES DE NIÑOS, NIÑAS Y ADOLECENTES</p>
        <p class="modal-text">
          En cumplimiento del artículo 7 de la ley 1581 de 2012 el tratamiento de los datos personales de los menores de edad se realizará, en el marco de las finalidades señaladas anteriormente y de acuerdo con los requisitos señalados en el artículo 2.2.2.25.2.9 del el Decreto 1074 de 2015 y la sentencia C-748 de 2011 de la Corte Constitucional.
  
          Para tal efecto, Apprevenir, sus empleados, funcionarios y encargados tendrán en cuenta que el tratamiento de los datos personales de menores de edad debe responder al interés superior de ellos y que debe asegurarse el respeto de sus derechos fundamentales como lo es la promoción, protección y recuperación de la salud los derechos sexuales y reproductivos.
  
          Cuando la autorización del tratamiento de los datos sea otorgada por intermedio de un representante, el representante garantizará el ejercicio del derecho del menor a ser escuchado previamente al otorgamiento de la autorización y valorará la opinión del menor teniendo en cuenta su madurez, autonomía y capacidad para entender el asunto.
  
          Los responsables y encargados del tratamiento de datos personales de menores de edad velarán por el uso adecuado de los datos y aplicarán los principios y obligaciones consagrados en la Ley 1581 de 2012 y el Decreto Único 1074 de 2015.
        </p>
        <br>
        <p class="modal-title">VI. DERECHOS DE LOS TITULARES</p>
        <p class="modal-text">
          Los Titulares de los datos personales tienen los siguientes derechos:
          <ol class="modal-text">
            <li>
              Conocer, acceder en forma gratuita, actualizar y rectificar sus datos personales frente al responsable o los encargados del tratamiento. Este derecho se podrá ejercer, entre otros frente a datos parciales, inexactos, incompletos, fraccionados, que induzcan a error, o aquellos cuyo tratamiento esté expresamente prohibido o no haya sido autorizado.
            </li>
            <li>
              Solicitar prueba de la autorización otorgada al responsable del tratamiento salvo cuando expresamente se exceptúe como requisito para el tratamiento, de conformidad con lo previsto en el artículo 10 de la Ley 1581 de 2012.
            </li>
            <li>
              Ser informado por el responsable o por el encargado del tratamiento, previa solicitud, respecto del uso que le ha sido dado a sus datos personales.
            </li>
            <li>
              Presentar ante las autoridades competentes quejas por infracciones a la Ley.
            </li>
            <li>
              Revocar la autorización y/o solicitar la supresión del dato cuando en el tratamiento no se respeten los principios, derechos y garantías constitucionales y legales. La revocatoria y/o supresión procederá cuando la Superintendencia de Industria y Comercio haya determinado que en el tratamiento el responsable o encargado han incurrido en conductas contrarias a la Constitución y la ley.
            </li>
          </ol>
        <p>
          <p class="modal-text">De acuerdo con el artículo 2.2.2.25.2.6 del Decreto Único 1074 de 2015, la solicitud de supresión de la información y la revocatoria de la autorización no procederán cuando el Titular tenga un deber legal o contractual de permanecer en la base de datos.
        <p>
        <br>
        <p class="modal-title">VII. PROCEDIMIENTO PARA EJERCICIO DE DERECHOS POR PARTE DE TITULARES</p>
        <p class="modal-text">
          Los Titulares de datos personales deben dirigir sus solicitudes o reclamos al correo electrónico XXX. Para el efecto harán uso del Formato de Solicitudes y Reclamaciones sobre el Tratamiento de Datos Personales que se encuentra disponible para ser descargado en el portal web. <br>
        </p>
        <p class="modal-text">1. Procedimiento para solicitudes y consultas: Apprevenir, debe atender las solicitudes y consultas en un término de diez (10) días hábiles contados a partir de la fecha en que se reciba la solicitud. Cuando no fuere posible cumplir con este tiempo, se deberá informar al interesado expresando los motivos de la demora y la fecha en que se atenderá su solicitud o consulta en un término no mayor a cinco (5) días hábiles siguientes al vencimiento del primer término. 
        <p>
  
        <p class="modal-text">
          2. Procedimiento en caso de reclamos: El titular o causahabiente que considere que la información contenida en una base de datos debe ser objeto de corrección, actualización o supresión, o cuando advierta el presunto incumplimiento de cualquiera de los deberes contenidos en la ley o en la presente Política, podrá presentar un reclamo a Apprevenir S.A.S., el cual será tramitado bajo las siguientes reglas:
        </p> 
          <ol class="modal-text">
            <li>
              El reclamo se formulará mediante solicitud dirigida a la Oficina de Protección y Tratamiento de Datos Personales, al correo electrónico XXX con la identificación del titular, la descripción de los hechos que dan lugar al reclamo, la dirección, y acompañando los documentos que se quiera hacer valer. Si el reclamo resulta incompleto, se requerirá al interesado dentro de los cinco (5) días siguientes a la recepción del mismo para que subsane las fallas. Transcurridos dos (2) meses desde la fecha del requerimiento, sin que el solicitante presente la información requerida, se entenderá que ha desistido del reclamo.
            </li>
            <li>
              Una vez recibido el reclamo completo, se incluirá en la base de datos una leyenda que diga "reclamo en trámite" y el motivo del mismo, en un término no mayor a dos (2) días hábiles. Dicha leyenda deberá mantenerse hasta que el reclamo sea decidido.
            </li>
            <li>
              El término máximo para atender el reclamo será de quince (15) días hábiles contados a partir del día siguiente a la fecha de su recibo. Cuando no fuere posible atender el reclamo dentro de dicho término, se informará al interesado los motivos de la demora y la fecha en que se atenderá su reclamo, la cual en ningún caso podrá superar los ocho (8) días hábiles siguientes al vencimiento del primer término.
            </li>
          </ol>
          <p class="modal-text">3. Procedimiento para la revocatoria de la autorización y/o solicitud de supresión del dato: Los Titulares podrán en todo momento solicitar a Apprevenir, la supresión de sus datos personales y/o revocar la autorización otorgada para el Tratamiento de los mismos, mediante la presentación de un reclamo, de acuerdo con lo establecido en el artículo 15 de la Ley 1581 de 2012, el Decreto Único 1074 de 2015 y el Procedimiento indicado en esta Política.
          Si vencido el término legal respectivo, Apprevenir, no hubiere eliminado los datos personales, el Titular tendrá derecho a solicitar a la Superintendencia de Industria y Comercio que ordene la revocatoria de la autorización y/o la supresión de los datos personales.
          No obstante, lo anterior, de acuerdo con los artículos 2.2.2.25.2.6 y 2.2.2.25.2.8 del Decreto Único 1074 de 2015, la solicitud de supresión de la información y la revocatoria de la autorización no procederán cuando el Titular tenga un deber legal o contractual de permanecer en la base de datos.</p>
        
        <br>
        <p class="modal-title">VIII. LIMITACIONES TEMPORALES AL TRATAMIENTO DE LOS DATOS PERSONALES</p>
        <p class="modal-text">
          Apprevenir, solo podrán recolectar, almacenar, usar o circular los datos personales durante el tiempo que sea razonable y necesario, de acuerdo con las finalidades que justificaron el tratamiento, atendiendo a las disposiciones aplicables a la materia de que se trate y a los aspectos administrativos, contables, fiscales, jurídicos e históricos de la información.
          Una vez cumplida la o las finalidades del tratamiento Apprevenir, o el encargado del tratamiento, según corresponda, procederán a la supresión de los datos personales en su posesión. No obstante lo anterior, los datos personales deberán ser conservados cuando así se requiera para el cumplimiento de una obligación legal o contractual.
        </p>
        <br>
        <p class="modal-title">IX. AUTORIZACIÓN</p>
        <p class="modal-text">
          Apprevenir recibirá la autorización para el tratamiento de datos personales mediante documento escrito, a través de su sitio web y/o su aplicación móvil. Para ello el titular podrá dar su consentimiento de forma escrita, verbal o por conductas inequívocas.
          El contenido de la autorización es el siguiente:
          Apprevenir recolectará datos personales privados y sensibles del titular, tales como su nombre, fecha de nacimiento, género al que pertenece y demás datos que el propio titular desee suministrar. Respecto de los datos sensibles, el titular no está obligado a suministrarlos pero, si desea hacerlo, serán aquellos necesarios para dar cumplimiento a las finalidades del tratamiento señaladas en el presente documento como lo son aquellos relativos a la salud, a la vida sexual y/o demás datos sensibles relacionados que el titular quiera suministrar.
          El titular manifiesta haber leído el presente Manual de Políticas para la Protección y Tratamiento de Datos personales. En consecuencia reconoce haber sido informado acerca de las finalidades específicas del tratamiento y otorga su autorización expresa para el tratamiento de sus datos personales, incluida la autorización expresa de transferencia y transmisión internacional de sus datos personales, dentro de las finalidades comunicadas en el presente documento.
          Para los titulares quienes opten por dar la autorización a través del sitio web o la aplicación, su consentimiento expreso se dará en el momento en que creen su perfil de usuario y acepten el contrato de Términos y Condiciones y la Política de Privacidad. Este acto será considerado una conducta inequívoca suficiente para concluir de forma razonable que la autorización fue expresamente otorgada.
        </p>
        <br>
        <p class="modal-title">X. VIGENCIA DE LA POLÍTICA</p>
        <p class="modal-text">
          La presente política entra en vigencia a partir del 01 de enero de 2018.
        </p>
    </div>
   
  </mat-dialog-content>

  <div align="end">
    <button class="btn btn-secondary" mat-dialog-close>Aceptar</button>
  </div>
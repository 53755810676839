import { Component } from '@angular/core';

@Component({
  selector: 'app-final-user',
  templateUrl: './final-user.component.html',
  styleUrls: ['./final-user.component.scss']
})
export class FinalUserComponent {
  
}

<!--Navbar-->
<mat-toolbar >
    <mat-icon (click)="drawer.toggle()" class="burger app-cursor">menu</mat-icon>
    <img alt="Logo" src="../../../assets/logo/COLOR-CONTORNO.svg" class="logo-app" />
    <span class="example-spacer"></span>
    <div [matMenuTriggerFor]="menu" class="menu-drop">
        <img src="../../../assets/icons/accountcircle_outline.svg" alt=""> 
        <label class="user-text app-m-name" style="cursor: pointer;">{{userName}}</label>
        <mat-icon  class="burger app-cursor">keyboard_arrow_down</mat-icon>
    </div>
        <mat-menu #menu="matMenu">
            <button mat-menu-item routerLink="admin" *ngIf="userIsAdmin">
                <mat-icon fontSet="material-icons-outlined">settings</mat-icon>
                <span class="user-text">Administrar</span>
            </button>
            <button mat-menu-item routerLink="/profile">
                <mat-icon fontSet="material-icons-outlined">account_circle</mat-icon>
                <span class="user-text">Mi perfil</span>
            </button>
            <button mat-menu-item (click)="logout()">
                <mat-icon fontSet="material-icons-outlined">logout</mat-icon>
                <span class="user-text">Cerrar sesión</span>
            </button>
        </mat-menu>
    
</mat-toolbar>

<mat-drawer-container class="example-container" autosize>
    <mat-drawer #drawer class="example-sidenav" mode="side">
        <div *ngIf="userIsAdmin" class="navigation-list">
            <div class="logo-client">
                <img alt="Logo" *ngIf="!logoOverride" src="../../../assets/logo/COLOR-CONTORNO.svg" class="logo-client__app" />
                <img alt="Logo" *ngIf="logoOverride" src="{{logoOverride}}" class="logo-client__app" />
            </div>
            <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
                <!-- This is the tree node template for leaf nodes -->
                <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
                  <!-- use a disabled button to provide padding for tree leaf -->
                  <button class="btn-list" mat-button routerLink="{{node.route}}"  routerLinkActive="router-link-active" >
                    <mat-icon fontSet="material-icons-outlined" >
                        {{node.icon}}
                    </mat-icon>
                   <span>{{node.name}}</span> 
                  </button>
                </mat-tree-node>
                <!-- This is the tree node template for expandable nodes -->
                <mat-tree-node *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding>
                  <button class="btn-list" mat-button  matTreeNodeToggle
                        [attr.aria-label]="'Toggle ' + node.name">
                    <mat-icon fontSet="material-icons-outlined" >
                        {{node.icon}}
                    </mat-icon>
                    <span>{{node.name}}</span> 
                  </button>
                </mat-tree-node>
              </mat-tree>
            <img src="./../../assets/bg/logo-luis-amigo.png" alt="" class="home-logo app-p-20">
            <p class="user-bottom app-p-20">© 2020. Universidad Católica Luis Amigó</p>
        </div>
        <div *ngIf="!userIsAdmin" class="home-list">
            <div class="logo-client">
                <img alt="Logo" *ngIf="!logoOverride" src="../../../assets/logo/COLOR-CONTORNO.svg" class="logo-client__app" />
                <img alt="Logo" *ngIf="logoOverride" src="{{imagesBaseUrl}}/{{logoOverride}}" class="logo-client__app" />
            </div>
            <a href="" target="_blank" class="user-text app-p-20">Inicio</a>
            <button *ngIf="role == 'client' && config != null" class="btn-list" mat-button routerLink="client/edit-final-user" routerLinkActive="router-link-active" >
                <mat-icon fontSet="material-icons-outlined" >
                    account_box
                </mat-icon>
                <span>Usuarios finales</span> 
            </button>
            <button *ngIf="role == 'client' && config != null" class="btn-list" mat-button routerLink="client/report" routerLinkActive="router-link-active" >
                <mat-icon fontSet="material-icons-outlined" >
                    content_paste
                </mat-icon>
                <span>Test informe</span> 
            </button>
            <a href="https://apprevenir.com/conocenos/" target="_blank" class="user-text app-p-20">Más sobre Apprevenir</a>
            <a href="https://apprevenir.com/contactanos/" target="_blank" class="user-text app-p-20">Contáctanos</a>
            <img src="./../../assets/bg/logo-luis-amigo.png" alt="" class="home-logo app-p-20">
            <p class="user-bottom app-p-20">© 2020. Universidad Católica Luis Amigó</p>
        </div>
    </mat-drawer>
    <mat-drawer-content>
        <router-outlet></router-outlet>
    </mat-drawer-content>
</mat-drawer-container>

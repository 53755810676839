<app-loader #loader></app-loader>

<div class="test-admin" [hidden]="loader.loadingData">
  <div class="test-admin__box" >
    <h1 class="test-admin__title app-m-top">Edición de Cliente</h1>
    <button
      mat-mini-fab
      color="primary"
      (click)="onClientCreationRequested()"
    >
      <mat-icon>add</mat-icon>
    </button>
  </div>
  <div class="test-admin__card">
    <div class="test-admin__box">
      <div class="home__search">
        <div class="inputContainer-search">
          <img
            alt="PersonIcon"
            src="../../../assets/icons/search_baseline.svg"
            class="inputContainer-search__icon"
          />
          <input
            class="inputContainer-search__box"
            type="text"
            placeholder="Palabra clave"
						[(ngModel)]="clientsFilter"
						(ngModelChange)="onClientsFilterChanged()"
          />
        </div>
      </div>
      <div align="end" class="btn-cancel">
        <button class="btn btn-file app-m-right" (click)="onExcelExportRequested()">
          <mat-icon class="icon-file">description</mat-icon>Excel
        </button>
        <!--<button class="btn btn-file" (click)="onPDFExportRequested()">
          <mat-icon class="icon-file">picture_as_pdf</mat-icon>PDF
        </button>-->
      </div>
    </div>
    <div class="example-table-container">
      <table
        mat-table
        [dataSource]="clientsDataSource"
        matSort
        matSortActive="position"
        matSortDisableClear
        matSortDirection="desc"
      >
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
            Id
          </th>
          <td mat-cell *matCellDef="let element">{{ element.id }}</td>
        </ng-container>
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
            Nombre
          </th>
          <td mat-cell *matCellDef="let element">{{ element.name }}</td>
        </ng-container>
        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
            Tipo
          </th>
          <td mat-cell *matCellDef="let element">{{ element.type }}</td>
        </ng-container>
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
            Estado
          </th>
          <td mat-cell *matCellDef="let element">{{ element.status }}</td>
        </ng-container>
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
            Edición
          </th>
          <td mat-cell *matCellDef="let element">
            <mat-icon fontSet="material-icons-outlined" class="app-cursor" (click)="onClientEditRequested(element)">edit</mat-icon>
            <mat-icon fontSet="material-icons-outlined" class="app-cursor" (click)="onClientRemovalRequested(element)">delete</mat-icon>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="clientColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: clientColumns"></tr>
      </table>
    </div>
    <mat-paginator [length]="clients.length" [pageSize]="30"></mat-paginator>
  </div>
</div>

<div class="login">
	<div class="login__section-one">
		<div class="section-one">
			<img alt="PersonIcon" src="../../../assets/logo/LOGO-COLOR-BLANCO.svg" class="logo-login" />
		</div>
		<img alt="PersonIcon" src="../../../assets/bg/appevenir-home-login.svg" class="logo-bg" />
	</div>
	<div class="login__section-two">
		<div class="section-two">
			<div class="section-two__title">Recuperar contraseña</div>
			<div class="section-two__text">¿Perdiste tu contraseña? Por favor, introduce tu o correo electrónico y recibirás allí las instrucciones.</div>
			<form class="example-form" [formGroup]="emailForm">
				<mat-form-field class="example-full-width">
					<mat-label>Email</mat-label>
					<input type="email" matInput formControlName="email">
					<mat-error *ngIf="emailForm.get('email').hasError('email') && !emailForm.get('email').hasError('required')">
						Por favor ingresar un correo electrónico válido
					</mat-error>
					<mat-error *ngIf="emailForm.get('email').hasError('required')">
						El correo electrónico es <strong>necesario</strong>
					</mat-error>
					<img src="./../../../assets/icons/email_outline.svg" alt="" matSuffix>
				</mat-form-field>
				<div>
					<button class="btn btn-primary width-100" (click)="onSubmit()">Enviar</button>
					<button class="btn btn-secondary app-m-top width-100" routerLink="/">Cancelar</button>
				</div>
			</form>
		</div>
	</div>
</div>
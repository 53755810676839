<mat-dialog-content class="mat-typography">
    <div class="modal-h3">Términos y condiciones generales de uso</div>
    <div class="modal-box">
     <p class="modal-title">I. Objeto</p><br><br>
      <p class="modal-text">
        Apprevenir permite, conforme a las direcciones y vínculos visibles, a sus Usuarios el acceso y la utilización de diversos servicios y contenidos 
        (en adelante, los "Servicios") puestos a disposición por Apprevenir o por terceros, bajo las condiciones acá indicadas.
      </p>
      <p class="modal-text">
        Los presentes términos y condiciones tienen por objeto establecer y regular las condiciones de acceso y uso general al SITIO WEB, APP o al servicio prestado 
        por medio del canal SMS (Short Message Service o mensajes de texto), en adelante EL SITIO, así como a los distintos contenidos y servicios prestados 
        por Apprevenir o por terceros desde y/o a través del mismo. En este último caso, cuando los contenidos y servicios no estén hospedados en 
        EL SITIO Apprevenir no se hace responsable por los mismos.
      </p>
      <p class="modal-text">
        Tanto el acceso como la utilización general del SITIO WEB, APP o el canal SMS atribuyen la condición de usuario del mismo (en adelante, el "USUARIO"), implicando tal acceso otorgado al usuario necesariamente la aceptación plena y 
        sin reservas de todas y cada una de las disposiciones y condiciones incluidas en los términos y condiciones generales de acceso y uso del mismo.
      </p>
      <p class="modal-text">
        El Usuario queda expresamente enterado y acepta que estas condiciones podrán experimentar variaciones en el futuro a libre criterio de Apprevenir y 
        sin obligación alguna de aviso previo por su parte que igualmente serán visibles en EL SITIO. Como efecto de lo anterior, será de la sola y exclusiva 
        responsabilidad del Usuario verificar los términos y condiciones vigentes en cada una de las ocasiones en que acceda al SITIO o de cualquier manera haga 
        utilización al SITIO WEB, APP o usando el canal SMS. La utilización de servicios ofrecidos a los Usuarios a través del SITIO WEB, APP o el canal SMS- en 
        particular el acceso y uso del ENTORNO TRANSACCIONAL o TIENDA ON-LINE que hace parte del SITIO WEB - podrá ser objeto de condiciones y configuraciones 
        de utilización y acceso particulares (en adelante, las "Condiciones Particulares") que, según los casos, podrán sustituir, completar y/o modificar los 
        presentes términos y condiciones generales.
      </p>
      <p class="modal-title">II. REGISTRO Y ACCESO</p><br><br>
      <p class="modal-text">
        Con carácter general el acceso general al SITIO WEB es libre y no exige el previo registro de Usuario. No obstante, Apprevenir podrá condicionar el acceso a determinadas y específicas áreas y la utilización de ciertos Servicios al 
        previo y completo diligenciamiento del correspondiente formulario de registro de Usuario, datos diligenciados por el usuario que Apprevenir administrará 
        para su propósito y para propósito de los aliados comerciales de Apprevenir, quienes son autorizados por el usuario a través del diligenciamiento completo 
        de su registro, conforme a lo dispuesto en las normas vigentes en materia de HABEAS DATA en Colombia (Ley 1581 de 2012 y normas que la reglamenten).
      </p>
      <p class="modal-text">
        Asimismo, Apprevenir podrá poner a disposición de los Usuarios ciertos y determinados Servicios para cuya utilización y acceso del Usuario pudiera 
        requerir el diligenciamiento de formulario de registro adicionales y/o satisfacción de determinadas contraprestaciones dinerarias que se 
        tramitarán por el Usuario bajo condiciones transaccionales seguras dispuestas por EL SITIO WEB, APP o el canal SMS. En su caso, dicho registro 
        se efectuará en la forma expresamente indicada en el formulario y anexos permitidos con el acceso al propio servicio y/o en las condiciones 
        particulares que lo regulen visibles en el vínculo dispuesto por el mismo sitio, datos diligenciados que Apprevenir administrará para su 
        objeto y para objeto de los aliados comerciales de Apprevenir , conforme a lo dispuesto en las normas vigentes en materia de HABEAS DATA en 
        Colombia (Ley 1581 de 2012 y normas que la reglamenten). El Usuario garantiza, al momento del registro, la veracidad del conjunto de los datos 
        diligenciados en los formularios en el SITIO a los cuales accede Apprevenir, información y datos de los cuales es el Usuario responsable de 
        mantenerlos completamente actualizados. En todo caso, el Usuario será el único responsable de las manifestaciones falsas o inexactas que realice con 
        el diligenciamiento de formularios en el Sitio y de los perjuicios que cause a Apprevenir y/o a terceros por la información falsa y/o inexacta que 
        incorpore en los formularios con el registro. Apprevenir se reserva el derecho de negar el ingreso, acceso y/o registro en el SITIO WEB, APP o el canal SMS 
        en cualquier momento y sin que deba mediar aviso, notificación, justificación y/o explicación alguna. Esta negativa no comportará derecho a indemnización 
        alguna. Los datos ingresados por el usuario al momento de su registro podrán ser utilizados y compartidos por Apprevenir con sus aliados comerciales; 
        igualmente, Apprevenir podrá a partir del registro del usuario y por el solo acto de registro, enviar información propia del Sitio y de sus aliados 
        comerciales a los correos de los usuarios, siempre conforme a lo dispuesto en las normas vigentes en materia de HABEAS DATA en Colombia 
        (Ley 1581 de 2012 y normas que la reglamenten). El sitio de Apprevenir y los sitios de su aliados son medios de acceso y/o transferencia de información de 
        interés de los usuarios quienes libremente y bajo su propia responsabilidad acceden y/o se registran conforme a los parámetros y condiciones del sitio, 
        razón por la cual la responsabilidad sobre los contenidos y alcances de las respuestas, información, datos y en general sobre los efectos de las redacciones 
        proveídas por los profesionales de la medicina transmitidas por el sitio de Apprevenir, es responsabilidad única y exclusiva de tales profesionales de la 
        salud que escriben tales respuestas, quienes igualmente obran por su propia cuenta y riesgo Una vez se complete satisfactoriamente el proceso de registro 
        por parte del Usuario, Apprevenir podrá incorporar al Usuario en su base de datos de Usuarios registrados, pudiendo utilizar dicha base de datos para el 
        desarrollo de su objeto y para el desarrollo del objeto de sus aliados conforme a lo dispuesto en las normas vigentes en materia de HABEAS DATA en Colombia 
        (Ley 1581 de 2012 y normas que la reglamenten). Una cuenta será generada con su dirección de correo electrónico y “palabra clave de acceso” asociada para 
        cada Usuario.
      </p>
      <p class="modal-title">III. POLÍTICA DE COOKIES</p><br><br>
      <p class="modal-text">
        Uso de ‘cookies’: El SITIO WEB de Apprevenir , conforme a la costumbre mercantil y a la costumbre incorporada al comercio electrónico, emplea ‘cookies de sesión’ con la finalidad de permitir y facilitar la interacción del usuario con el mismo sitio. Asimismo los servicios de estadísticas –propios o ajenos-, empleados por el SITIO WEB de Apprevenir pueden emplear ‘cookies’ con la finalidad de establecer métricas y patrones de uso del sitio. El acceso, uso, registro, y, en general, la simple navegación por el SITIO WEB de Apprevenir suponen necesariamente el consentimiento del usuario a la utilización de “cookies” por parte de Apprevenir y/o de sus proveedores de servicios, conforme a lo dispuesto en las normas vigentes en materia de HABEAS DATA en Colombia (Ley 1581 de 2012 y normas que la reglamenten).

        ¿Qué son las cookies? Una cookie es un archivo que se descarga en su ordenador al acceder a determinadas páginas web. Las cookies permiten a una página web, entre otras cosas, almacenar y recuperar información sobre los hábitos de navegación de un usuario o de su equipo y, dependiendo de la información que contengan y de la forma en que utilice su equipo, pueden utilizarse para reconocer al usuario.

        ¿Qué tipos de cookies utiliza esta página web?

        Cookies de análisis: Son aquéllas que, bien tratadas por nosotros o por terceros, nos permiten cuantificar el número de usuarios y así realizar la medición y análisis estadístico de la utilización que hacen los usuarios del servicio ofertado. Para ello se analiza su navegación en nuestra página web con el fin de mejorar la oferta de productos o servicios que le ofrecemos.

        Cookies publicitarias: Son aquéllas que, bien tratadas por nosotros o por terceros, nos permiten gestionar de la forma más eficaz posible la oferta de los espacios publicitarios que hay en la página web, adecuando el contenido del anuncio al contenido del servicio solicitado o al uso que realice de nuestra página web. Para ello podemos analizar sus hábitos de navegación en Internet y podemos mostrarle publicidad relacionada con su perfil de navegación.

        Usted puede permitir, bloquear o eliminar las cookies instaladas en su equipo mediante la configuración de las opciones del navegador instalado en su ordenador. En la función “Ayuda” de la mayoría de los navegadores se indica cómo configurar su navegador para que no acepte cookies, para que le notifique cada vez que reciba una nueva cookie, así como para desactivar completamente todas las cookies. Además, puede deshabilitar o eliminar datos similares utilizados por las extensiones (add-on) de los navegadores, como las “Flash Cookies”, cambiando la configuración de dichas extensiones o visitando el sitio web del fabricante.
      </p>
      <p class="modal-title">IV. OBLIGACIONES DEL USUARIO.</p><br><br>
      <p class="modal-text">
        Serán obligaciones del Usuario, sin perjuicio de las que surjan a partir del clausulado de este documento y de las que dispongan la ley, las siguientes:
        <ul>
          <li>
            Garantizar la veracidad y exactitud de los datos de registro introducidos en los sistemas de información de Apprevenir y llevar a cabo cuantas actualizaciones de los mismos fueran necesarias para la eficiente obtención de los servicios ofrecidos por el sitio. En todo caso el Usuario será el único responsable de las manifestaciones falsas o inexactas que realice y de los perjuicios que cause a Apprevenir o a terceros por la información que facilite.
          </li>
          <li>
            Custodiar, proteger y preservar los datos correspondientes a el “usuario” y la “palabra clave de acceso” otorgados por Apprevenir a el Usuario con ocasión del diligenciamiento de su registro y/o de los formularios dispuestos respectivamente por el sitio, impidiendo el acceso de terceras personas no autorizados a las mismos y por tanto asegurando su confidencialidad.
          </li>
          <li>
            Notificar a Apprevenir con carácter inmediato cualquier indicio sobre la existencia de una violación en la seguridad SITIO WEB, así como de usos inapropiados y/o prohibidos conforme a la ley colombiana, de los servicios prestados desde el mismo, o de fallos de seguridad de cualquier índole relacionados directa o indirectamente con los mismos servicios a los cuales accede desde el sitio.
          </li>
          <li>
            Hacer buen uso de los contenidos, información y servicios prestados desde o a través del SITIO WEB, APP o canal SMS, conforme a la ley colombiana, la buena fe y a las buenas costumbres generalmente aceptadas, comprometiéndose expresamente a: <br>
            
            ° Abstenerse de realizar prácticas o usos respecto de los servicios ofrecidos por el SITIO con fines ilícitos, fraudulentos, lesivos de derechos o intereses de Apprevenir o de terceros, infractores de las normas contenidas en la ley colombiana, en el presente catálogo o en las Condiciones Particulares que en su caso regulen cualquiera de los servicios prestados desde o a través del SITIO WEB.<br>
              
            ° Abstenerse de realizar cualquier tipo de acción que pudiera inutilizar, sobrecargar o dañar sistemas, equipos o servicios del SITIO WEB o accesibles directa o indirectamente a través del SITIO WEB o de aliados de Apprevenir.<br>
              
            ° Respetar los derechos de autor y de propiedad industrial de Apprevenir y de terceros sobre los, signos distintivos, contenidos, información y servicios prestados desde o a través del SITIO WEB, absteniéndose con carácter general de reproducir, copiar, distribuir, reproducir, comunicar en forma alguna los mismos a terceros, o de cualquier manera usar en su provecho o en provecho de terceros, siempre que no medie autorización expresa y por escrito de 1DOC3 o de los titulares de dichos derechos. 
          </li>
        </ul>
      <p class="modal-title">V. PROPIEDAD INTELECTUAL</p><br><br>
      <p class="modal-text">
        SITIO WEB: Todo el software y los contenidos generales del SITIO WEB, incluidos: textos, animaciones, imágenes etc. así como las marcas, nombres comerciales y/o signos distintivos mostrados o que se desplieguen como efecto o consecuencia de vínculos ubicados en el SITIO son propiedad de Apprevenir o de terceros que han autorizado a 1DOC3 y están protegidos por las leyes nacionales e internacionales de propiedad intelectual.

        Cualquier uso ajeno a la ejecución pública del SITIO WEB por parte de Apprevenir , incluida la reproducción, modificación, distribución, transmisión, reedición, arreglos o representación de cualesquiera elementos del mismo está estrictamente prohibido salvo consentimiento expreso escrito de Apprevenir .

        CONTENIDOS INTRODUCIDOS POR LOS USUARIOS: El usuario será el único y exclusivo responsable sobre cualesquiera contenidos introducidos o publicados por el mismo en el SITIO WEB, APP por el canal SMS o en sitios de aliados de cualquier manera vinculados al SITIO y, en particular, en la sección “PREGUNTA-RESPUESTA” del mismo, y será por tanto único responsable de cualquier consecuencia que pudiese derivarse de tales publicaciones, eximiendo expresamente a Apprevenir de cualquier responsabilidad a este respecto. No obstante, el Usuario autoriza a Apprevenir a utilizar las preguntas realizadas y enviadas al SITIO WEB con cualquier propósito relacionado con el objeto de Apprevenir.

        El usuario se compromete a no incluir en el SITIO WEB y, en particular, en la sección “PREGUNTA-RESPUESTA” del mismo, información o contenidos con explícita finalidad comercial, xenófoba, política, religiosa, o de cualquier manera violatoria de derechos de Apprevenir y/o de terceros, ni aquellos que pudiesen ser de alguna manera contrarios a los derechos de morales o patrimoniales de autor de Apprevenir y/o de terceros, o violatorios de la ley, las buenas costumbres y/o a los presentes Términos y Condiciones Generales.

        El usuario confiere a Apprevenir una licencia de uso y provecho irrevocable, de ámbito mundial y perpetua (o, en su defecto, por todo el espacio de tiempo previsto por la legislación nacional e internacional vigente), sobre cualesquiera informaciones o contenidos introducidos o publicados por el mismo en el SITIO WEB y, en particular, en la sección “PREGUNTA-RESPUESTA” del mismo, para reproducir y comunicar públicamente los mismos, así como para –en su caso- agregar información a los mismos y/o para transformarlos con el objeto de adaptarlos a las necesidades técnicas y/o comerciales de los distintos servicios prestados desde y/o a través del SITIO WEB.

        En relación con el contenido publicado en el SITIO WEB por el Usuario, y, en particular, en la sección “PREGUNTA-RESPUESTA” del mismo, el usuario garantiza a Apprevenir:
        <ul>
          <li>
            Ser el propietario y/o titular de los derechos que permiten conceder a Apprevenir la licencia para su uso y/o publicación y que, en su caso, ha obtenido de terceros el consentimiento y autorizaciones necesarias para ello, debiendo en cualquier caso proveer indemnidad a Apprevenir en caso de que tales usos del Usuario vulneren derechos de terceros.
          </li>
          <li>
            Que NO vulnera leyes aplicables, tales como las relativas al derecho a la intimidad, a la imagen y/o al honor, derechos de propiedad intelectual o industrial o similar, ni en general ningún derecho de tercero, ya sea una persona física o una persona jurídica.
          </li>
          <li>
            Que en caso de publicación de datos de carácter personal de persona física alguna, ha informado exhaustivamente y obtenido previamente el consentimiento necesario para la publicación de dichos datos por parte del/os afectado/s y/o implicados con tal uso de información.
          </li>
          <li>
            El usuario responderá frente a Apprevenir de la veracidad de lo afirmado, manteniendo indemne a Apprevenir ante cualquier demanda, reclamación o queja presentada por un tercero en relación con cualesquiera contenidos introducidos o publicados por el mismo en el SITIO WEB y, en particular, en la sección “PREGUNTA-RESPUESTA” del mismo.
          </li>
        </ul>
      <p class="modal-title">VI. GARANTÍAS</p><br><br>
      <p class="modal-text">
        El acceso al SITIO WEB, APP o canal SMS no implica o de cualquier manera deriva ningún tipo de garantía y/o resultado en favor del Usuario y/o tercero, que expresamente no se otorga y/o concede por Apprevenir , respecto a la calidad, veracidad, exactitud, licitud, actualidad o vigencia, así como la utilidad o adecuación a finalidad alguna de quien acceda a ellas. Tanto el acceso al SITIO WEB como el uso que pueda hacerse de la información y contenidos incluidos en el mismo o que sea accesible desde el mismo, se efectúa bajo la exclusiva responsabilidad del Usuario.

        Apprevenir no responderá en ningún caso y en ninguna medida, ni por daños directos ni indirectos, ni por daño emergente ni por lucro cesante, por los eventuales perjuicios derivados del uso de la información y contenidos del SITIO WEB, APP o el canal SMS accesibles desde o a través del mismo.

        Apprevenir NO garantiza en modo alguno la calidad, veracidad, exactitud, licitud, actualidad o vigencia, o resultado, así como la utilidad o adecuación a finalidad alguna de los contenidos que integran el SITIO WEB, APP o el canal SMS.

        Apprevenir excluye, con toda la extensión permitida por la Ley, cualquier responsabilidad de cualquier clase por la calidad, veracidad, exactitud, licitud, actualidad, resultado, vigencia o utilidad/adecuación a finalidad alguna que el Usuario pudiera haber atribuido u otorgado para sí o para terceros, a los contenidos que integran el SITIO WEB, APP o el canal SMS.

        El usuario queda enterado y acepta con el simple ingreso, navegación y/o registro en el Sitio que cualesquiera contenidos e información de índole sanitario y/o de salud contenida en el SITIO WEB, APP o el canal SMS tiene carácter meramente informativo y NO CONSTITUYE EN NINGÚN CASO UN SERVICIO DE DIAGNÓSTICO MÉDICO, DE PRESCRIPCIÓN DE TRATAMIENTOS FAMACOLÓGICOS. Se advierte y pone en conocimiento del usuario, que la información y/o contenidos del SITIO WEB, APP o el canal SMS NO puede en ningún caso sustituir la consulta presencial personal frente a un profesional y/o facultativo médico debidamente cualificado conforme a la legislación colombiana vigente.

        EL contenido intercambiado en la PREGUNTA-RESPUESTA en NINGÚN caso constituye una relación MÉDICO-PACIENTE ante la ley colombiana.

        El usuario declara entender y aceptar sin reserva de ninguna clase las características y limitaciones inherentes a la información contenida en el SITIO WEB, APP o el canal SMS. De conformidad con las condiciones de transacción comercial vigente en Colombia aplicables al Sitio, la edad mínima para el registro de usuario en el SITIO WEB, APP o el canal SMS es de 18 años de edad. El uso del SITIO WEB, APP o el canal SMS por parte del usuario constituye declaración por su parte de contar con -al menos-, 18 años de edad. El usuario garantiza la veracidad de dicha declaración y libera a Apprevenir de cualquier consecuencia derivada directa o indirectamente de la inexactitud o falta de veracidad de la misma.

        Apprevenir declara explícitamente que la información de salud proporcionada en el SITIO WEB, APP o canal SMS (textos, vídeos, tratamientos, tablas, gráficos, fotografías, imágenes, consejos, mensajes y cualquier otro material) tiene únicamente un fin informativo y, en ningún caso, sustituye el consejo médico, incluso en el caso de ser un médico identificado en el SITIO WEB, APP o el canal SMS quien proporcione dicha información, siendo el SITIO WEB o APP un mero y simple intermediario de información.

        Sugeridamente El Usuario, deberá consultar SIEMPRE a su médico, hablar con él o acudir a su consulta para cualquier duda de salud que pueda tener. En ningún caso, la lectura de la información vertida en este SITIO WEB, APP o el canal SMS debe hacer y/o de cualquier manera determinar la idea según la cual deba retrasarse la consulta directa y personal con un médico y/o facultativo conforme a la ley colombiana, ni hacer caso omiso de sus recomendaciones.

        El SITIO WEB podrá contener enlaces a páginas que, aún dentro del SITIO WEB se encuentran bajo responsabilidad de terceros. Asimismo, el SITIO WEB, APP podrá contener enlaces a páginas externas al mismo. En ambos casos, Apprevenir no puede controlar o supervisar en modo alguno dicho contenido. Apprevenir excluye, con toda la extensión permitida por la Ley, cualquier responsabilidad de cualquier clase, derivada de la existencia o posibilidad de acceso a dichos contenidos.

        Asimismo, Apprevenir no será en ningún caso responsable, ni siquiera de forma indirecta o subsidiaria, por productos o servicios prestados u ofertados por otras personas o entidades, o por contenidos, informaciones, comunicaciones, opiniones o manifestaciones de cualquier tipo originados o vertidos por terceros y que resulten accesibles a través de las Páginas Web de Apprevenir.

        Seguridad y Privacidad: Apprevenir empleará razonablemente los medios a su alcance para proveer de sistemas de seguridad que protejan de forma razonable sus sistemas y los datos contenidos en los mismos contra ataques deliberados, software maligno, etc. No obstante, el Usuario entiende y acepta los aspectos que comporta la prestación de servicios a través de Internet dado el carácter abierto, descentralizado y global de esta red de comunicaciones.

        Por este motivo Apprevenir NO garantiza la inexpugnabilidad de sus sistemas de seguridad ni la privacidad de la información alojada en los mismos. Apprevenir excluye, con toda la extensión permitida por la Ley, cualquier responsabilidad de cualquier clase por fallos de seguridad en el SITIO WEB, APP o el canal SMS y las consecuencias que de ellos pudieran derivarse.

        Interrupciones y/o Suspensión del Sistema: Con carácter general los diferentes servicios estarán disponibles ininterrumpidamente en Internet. Sin embargo, el Usuario declara conocer y acepta con el simple ingreso, navegación y/o registro en el Sitio que Apprevenir NO garantiza en modo alguno esta continuidad debido a la naturaleza del medio a través del que se prestan.

        El Usuario entiende y acepta los aspectos que comporta la prestación de servicios a través de Internet dado el carácter abierto, descentralizado y global de la red.

        Así, por ejemplo, a título enunciativo no exhaustivo, a continuación, se recogen algunas de las situaciones que pueden interrumpir temporalmente la prestación de dichos servicios:
        <ul>
          <li>
            Tareas de mantenimiento en los servidores y/o líneas de datos
          </li>
          <li>
            Averías en los servidores y/o líneas de datos
          </li>
          <li>
            Ataques deliberados contra la seguridad e integridad del sistema
          </li>
          <li>
            Virus informáticos o presencia de código maligno en las páginas que conforman o son accesibles desde el SITIO WEB Apprevenir excluye, con toda la extensión permitida por la Ley, cualquier responsabilidad de cualquier clase derivada de la interrupción temporal en la disponibilidad del SITIO WEB en la red Internet.
          </li>
        </ul>
        El Usuario declara conocer y acepta con el simple ingreso, navegación y/o registro en el Sitio que Apprevenir podrá en cualquier momento, a su solo criterio y sin obligación alguna de aviso previo, suspender con carácter definitivo la presencia del SITIO WEB en la red Internet y por tanto de los servicios prestados desde o a través del mismo, sin perjuicio de lo que se hubiere dispuesto al respecto en las correspondientes Condiciones Particulares. Apprevenir queda eximida de cualquier tipo de responsabilidad de cualquier clase derivada de las consecuencias que pudieran producirse por la suspensión definitiva en la prestación de dichos servicios.

        El usuario médico al darse de alta en la plataforma acepta como médico, que las respuestas generadas en la plataforma, sean utilizadas por Apprevenir para análisis estadísticos, propósitos de investigación y fines comerciales.

      <p class="modal-title">VII. CANCELACIÓN DEL USUARIO Y RESPONSABILIDADES</p><br><br>
      <p class="modal-text">
        Cualquier Usuario podrá solicitar su baja y/o eliminación de las bases de datos de Apprevenir, en cualquier momento, como usuario del SITIO WEB, APP o el canal SMS.

        1DOC3 dispondrá de un plazo de treinta (30) días para examinar la situación de dicho Usuario con respecto de la totalidad de servicios a que se hallare suscrito para, en su caso, emitir factura por todos aquellos consumos, costos, gastos y/o emolumentos que a la recepción de la petición de baja se hallaren pendientes de cobro en favor de Apprevenir. De no existir importe alguno pendiente de liquidación y/o cobro, la baja se llevará a efecto con carácter inmediato. En caso contrario, la baja quedará supeditada en el tiempo y condiciones al cobro efectivo de dichos importes por parte de Apprevenir.

        El Usuario responde por el buen uso del SITIO WEB, APP o el canal SMS y de los servicios contratados desde o a través del mismo. Cualquier uso de los servicios en contrario por parte del Usuario, comportará la inmediata exclusión del SITIO WEB, APP o el canal SMS con pérdida de su condición de usuario registrado del mismo, sin derecho a indemnización alguna y sin perjuicio de las correspondientes acciones legales a que por parte de Apprevenir hubiere lugar conforme a la ley colombiana o del lugar en el cual pueda actuarse contra el usuario.

        La falsedad de los datos personales y/o profesionales ingresados por el Usuario durante el proceso de registro en el SITIO WEB o en cualquiera de los servicios prestados desde o a través del mismo podrá comportar la inmediata exclusión del SITIO WEB, APP o el canal SMS con pérdida de su condición de usuario registrado del mismo, sin derecho a indemnización alguna y sin perjuicio de las correspondientes acciones legales a que por parte de Apprevenir o de terceros hubiere lugar.

        Cualquier tipo de acción por parte del usuario que pudiera poner en riesgo o dañar sistemas, equipos o servicios accesibles directa o indirectamente a través del SITIO, comportará su inmediata exclusión del mismo con pérdida de su condición de usuario registrado del mismo, sin derecho a indemnización alguna y sin perjuicio de las correspondientes acciones legales y reclamaciones de perjuicios que por parte de Apprevenir hubiere lugar.

        Cualquier tipo de acción por parte del usuario que vulnere los presentes términos y condiciones, las Condiciones Particulares que pudieran regular Servicios concretos, la Ley o las buenas costumbres generalmente aceptadas en Colombia o en el país en el cual se ubique el usuario, comportará su inmediata exclusión del SITIO WEB, APP o el canal SMS con pérdida de su condición de usuario registrado del mismo, sin derecho a indemnización alguna y sin perjuicio de las correspondientes acciones legales a que por parte de Apprevenir hubiere lugar.

        El Usuario será el único responsable frente a cualquier reclamación, acción legal, judicial o extrajudicial, iniciada por terceras personas tanto contra el propio Usuario como contra Apprevenir , basada en usos de los servicios contrarios a la ley, la moral, la buena fé y a las buenas costumbres generalmente aceptadas.

        Los usuarios médicos manifiestan que las respuestas generadas por ellos en la plataforma se mantendrán visibles a los usuarios de la misma para que las sigan consultando así no se encuentren activos y por el sólo hecho de aceptar estos términos y condiciones acuerdan ceder todos sus derechos de autor sobre las/sus respuestas.
      
      <p class="modal-title">VIII. NOTIFICACIONES</p><br><br>
      <p class="modal-text">
        El correo electrónico facilitado por el Usuario durante el proceso de registro en el SITIO WEB, será el utilizado por Apprevenir a efectos de practicar notificaciones al Usuario.

        El Usuario está obligado a mantener debidamente actualizados los datos que a efectos de notificaciones se referencian en la presente cláusula. En caso de que Apprevenir de cualquier manera detecte que el usuario ha faltado a la verdad o incorpore imprecisiones en el proceso de registro al sitio, podrá inmediatamente y sin previo aviso eliminar al usuario de sus bases de datos y/o de los procesos de registro y hospedaje del sitio.

        Todas las Notificaciones que efectúe Apprevenir al Usuario se considerarán válidamente efectuadas si se han realizado empleando los datos ingresados de cualquier manera por el usuario al sitio, a través de los medios anteriormente señalados. Apprevenir no se responsabiliza de cualquier daño que pudiere producirse por la vulneración del Usuario de su obligación de mantenimiento actualizado de sus datos de contacto.
      
      <p class="modal-title">IX. VALIDEZ Y ACUERDO COMPLETO</p><br><br>
      <p class="modal-text">
        La declaración de nulidad o invalidez de cualquier estipulación contenida en los presentes términos y condiciones, no afectará a la validez y eficacia de las demás cláusulas y condiciones.

        Los presentes términos y condiciones de los servicios prestados por Apprevenir a través del SITIO WEB constituyen el acuerdo total entre las partes, considerándose de rango superior a cualquier acuerdo previo escrito o verbal, entendimiento, afirmación, representación, negociación o propósito de acuerdo en relación con esta materia, previos.
      
      <p class="modal-title">X. LEGISLACIÓN Y JURISDICCIÓN APLICABLE</p><br><br>
      <p class="modal-text">
        Los presentes términos y condiciones se regirán e interpretarán en todos y cada uno de sus extremos por la Ley Colombiana. Tanto Apprevenir como el Usuario se comprometen a intentar resolver de manera amistosa cualquier desacuerdo que pudiera surgir en el desarrollo de sus relaciones mercantiles y/o comerciales.
      
       
    
    </div>
   
  </mat-dialog-content>

  <div align="end">
    <button class="btn btn-secondary" mat-dialog-close>Aceptar</button>
  </div>
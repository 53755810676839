<div class="test-admin">
    <div class="admin__card">
        <div class="section-two">
            <user-system-form 
            profile-update="true"
            admin-mode="true"
            [user-id-override]="userId">
            </user-system-form>
        </div>
    </div>
</div>
<div class="login">
	<div class="login__section-one">
		<div class="section-one">
			<img alt="PersonIcon" src="../../../assets/logo/LOGO-COLOR-BLANCO.svg" class="logo-login" />
		</div>
		<img alt="PersonIcon" src="../../../assets/bg/appevenir-home-login.svg" class="logo-bg" />
	</div>

	<div class="login__section-two">
		<div class="login__section--margin">
			<profile-form></profile-form>
		</div>
	</div>
</div>
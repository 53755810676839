<div class="login">
	<div class="login__section-one">
		<div class="section-one">
			<img alt="PersonIcon" src="../../../assets/logo/LOGO-COLOR-BLANCO.svg" class="logo-login" />
		</div>
		<img alt="PersonIcon" src="../../../assets/bg/appevenir-home-login.svg" class="logo-bg" />
	</div>
	<div class="login__section-two">
		<div class="section-two sectionObligated">
			<div class="imageDisplay">
				<img alt="PersonIcon" src="../../../assets/logo/LOGO-APPREVENIR-SOBRE-BLANCO.svg" class="" />
			</div>
			<div class="section-two__title">Acceder</div>
			<div class="section-two__text"> Ingresa tu usuario y contraseña para obtener acceso a todas las funcionalidades</div>
			<form class="example-form" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
				<mat-form-field class="example-full-width">
					<mat-label>Email</mat-label>
					<input type="email" matInput formControlName="email">
					<mat-error *ngIf="loginForm.get('email').hasError('email') && !loginForm.get('email').hasError('required')">
						Por favor ingresar un correo electrónico válido
					</mat-error>
					<mat-error *ngIf="loginForm.get('email').hasError('required')">
						El correo electrónico es <strong>necesario</strong>
					</mat-error>
					<mat-icon fontSet="material-icons-outlined" matSuffix class="icon-color">email</mat-icon>
				</mat-form-field>
				<div class="section-two__forgot-box">
					<a routerLink="/recovery" class="section-two__forgot">¿Olvídaste tu contraseña?</a>
				</div>
				<mat-form-field class="example-full-width">
					<mat-label>Contraseña</mat-label>
					<input [type]="show ? 'text' : 'password'" placeholder="Contraseña" formControlName="password" matInput>
					<mat-error *ngIf="loginForm.get('password').hasError('required')">
						La contraseña es <strong>necesaria</strong>
					</mat-error>
					<mat-icon  matSuffix *ngIf="show" (click)="password()" class="app-m-rigth icon-color app-cursor">visibility</mat-icon>
					<mat-icon  matSuffix *ngIf="!show" (click)="password()" class="app-m-rigth icon-color app-cursor">visibility_off</mat-icon>
					<mat-icon fontSet="material-icons-outlined" class="icon-color " matSuffix>https</mat-icon>
				</mat-form-field>
				<!-- <div class="box-checkbox">
					<input type="checkbox" id="test1" />
					<label for="test1">Recuérdame en este dispositivo</label>
				</div> -->
				<button class="btn btn-primary width-100" routerLink="app/home">Acceder</button>
				<div class="section-two__divider">
					<div class="section-two__empty"></div>
					<div class="section-two__label">¿No tienes una cuenta?</div>
				</div>
				<button class="btn btn-secondary width-100" routerLink="/registry">Crear una cuenta</button>
				<div class="section-two__termns">
					Al continuar, estás confirmando que has leído nuestros <a (click)="openDialog()" class="terms-modal">términos y condiciones</a> <span class="terms-span"> y </span> <a (click)="openDialogPolicy()" class="terms-modal">política de privacidad</a>
				</div>
			</form>
		</div>
	</div>
</div>

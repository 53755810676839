<div class="login">
	<div class="login__section-one">
		<div class="section-one">
			<img alt="PersonIcon" src="../../../assets/logo/LOGO-COLOR-BLANCO.svg" class="logo-login" />
		</div>
		<img alt="PersonIcon" src="../../../assets/bg/appevenir-home-login.svg" class="logo-bg" />
	</div>
	<div class="login__section-two">
		<div class="section-two">
			<div class="section-two__title">Restablecer la contraseña</div>
			<form class="example-form" [formGroup]="passwordForm">
				<mat-form-field class="example-full-width">
					<mat-label>Nueva contraseña</mat-label>
					<input type="password" matInput required formControlName="password">
					<mat-error>
						La contraseña debe tener entre 8 y 30 caracteres
					</mat-error>
					<img src="./../../../assets/icons/email_outline.svg" alt="" matSuffix>
				</mat-form-field>

                <mat-form-field class="example-full-width">
					<mat-label>Vuelva a escribir la contraseña</mat-label>
					<input type="password" matInput required formControlName="passwordConfirmation">
					<mat-error>
						La confirmación debe ser igual a la contraseña
					</mat-error>
					<img src="./../../../assets/icons/email_outline.svg" alt="" matSuffix>
				</mat-form-field>
				<div>
					<button class="btn btn-primary width-100" (click)="onSubmit()">Enviar</button>
					<button class="btn btn-secondary app-m-top width-100" routerLink="/">Cancelar</button>
				</div>
			</form>
		</div>
	</div>
</div>
export const EducationLevels = [{
  key: "1",
  label: "Primaria"
}, {
  key: "2",
  label: "Bachillerato"
}, {
  key: "3",
  label: "Universidad"
}, {
  key: "4",
  label: "Posgrado"
}, {
  key: "5",
  label: "Ninguno"
}];